/* eslint-disable func-names */
import { call, put } from 'redux-saga/effects'
import axios, { AxiosError } from 'axios'
import api from '../../../services/api'
import {
  getListPlansSuccess,
  getListPlansFailure,
  newSubscriptionFailure,
  cancelSubscriptionFailure,
  purchaseSuccess,
  purchaseFailure,
  paymentSuccess,
  transactionSuccess,
  transactionFailure,
  paymentFailure,
  calculateInstallmentsSuccess,
  calculateInstallmentsFailure,
  getPlanSuccess,
  getPlanFailure,
  calculateInstallmentsRequest,
  paymentWaiting,
  discountCouponSuccess,
  discountCouponFailure,
} from './actions'
import history from '../../../routes/history'
import { Error } from '../../../types/Error'
import { notificationOpenRequest } from '../Notification/actions'
import { refreshUser } from '../Auth/actions'
import { CreditCard } from '../../../types/CreditCard'
import { DiscountCoupon } from '../../../types/DiscountCoupon'
import { DiscountCouponType } from '../../../enums/DiscountCouponType'

export function* getListPlans(): any {
  try {
    const response = yield call(api.get, '/plans')
    yield put(getListPlansSuccess(response.data))
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            getListPlansFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao carregar os planos',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            getListPlansFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* getPlan(data: any): any {
  try {
    const response = yield call(api.get, `/plans/${data.payload.planId}`)
    yield put(getPlanSuccess(response.data))
    yield put(calculateInstallmentsRequest(response.data.value))
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            getPlanFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao carregar o pacote',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            getPlanFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* newSubscription(data: any): any {
  try {
    yield call(api.post, '/subscriptions', { plan: data.plan })
  } catch (e) {
    yield put(newSubscriptionFailure(e))
  }
}

export function* cancelSubscription(data: any): any {
  try {
    yield call(
      api.patch,
      `/subscriptions/${data.id}/cancel`,
      { status: data.status },
      {
        headers: { 'Content-Type': 'application/ld+json' },
      },
    )
  } catch (e) {
    yield put(cancelSubscriptionFailure(e))
  }
}

export function* purchase(data: any): any {
  try {
    const response = yield call(api.get, `${data.payload.planId}`)
    yield put(purchaseSuccess(response.data))
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            purchaseFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao carregar os planos',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            purchaseFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* payment(data: any): any {
  try {
    let payload: {
      plan?: string
      paymentMethod?: string
      creditCard?: CreditCard
      planCoupon?: string
      autoRenew?: boolean
    } = {
      plan: `/plans/${data.payload?.planId}`,
      paymentMethod: data.payload?.paymentMethod,
      autoRenew: data.payload?.autoRenew,
    }

    if (data.payload?.creditCard?.number) payload.creditCard = data.payload?.creditCard
    if (data.payload?.planCoupon) payload.planCoupon = data.payload?.planCoupon

    const response = yield call(api.post, '/subscriptions', payload)

    if ('transactions' in response.data && response.data.transactions.length) {
      for (let trans of response.data.transactions) {
        if (trans?.status === 'paid' || trans?.status === 'analyzing') {
          yield put(paymentSuccess(response.data))
          yield put(transactionSuccess(response.data.transaction))

          yield put(refreshUser())
          yield call(history.push, '/restricted-area/teacher/my-plan')
        }
        if (trans?.status === 'waiting_payment') {
          yield put(
            paymentWaiting(
              response.data.transactions.find((trans) => trans.status === 'waiting_payment'),
            ),
          )
        }
        if (trans?.status === 'refused' || trans?.status === 'default') {
          throw new ReferenceError({
            ...response.data,
          })
        }
      }
    } else {
      yield put(paymentSuccess(response.data))
      yield put(transactionSuccess(response.data.transaction))

      yield put(refreshUser())
      yield call(history.push, '/restricted-area/teacher/my-plan')
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 400) {
          yield put(notificationOpenRequest('Erro', 'Pagamento recusado', 'error', 4000))
          yield put(
            paymentFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Pagamento recusado!',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else if (error?.response?.status === 422) {
          yield put(
            notificationOpenRequest('Erro', 'Revise os dados e tente novamente', 'error', 4000),
          )
          yield put(
            paymentFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Pagamento recusado',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else if (error?.response?.status === 403) {
          yield put(
            notificationOpenRequest('Erro', 'Você já possui uma assinatura ativa', 'error', 4000),
          )
          yield put(
            paymentFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Você já possui uma assinatura ativa',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else if ([404, 500].includes(error?.response?.status)) {
          yield put(
            notificationOpenRequest(
              'Erro',
              'Ocorreu um erro ao realizar o pagamento. Revise os dados e tente novamente',
              'error',
              4000,
            ),
          )
          yield put(
            paymentFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao concluir o pagamento do pacote.',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            paymentFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* calculate_installments(data: any): any {
  try {
    const response = yield call(api.get, `/calculate_installments?amount=${data.payload.amount}`)
    yield put(calculateInstallmentsSuccess(data.payload.amount, response.data.installments))
  } catch (error) {
    yield put(calculateInstallmentsFailure(error))
  }
}

export function* discount_coupon(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/plan_coupons/validate/${data.payload.coupon}/${data.payload.id}`,
    )
    if (response.status === 200) {
      let couponUsed = response.data as DiscountCoupon
      let orderRest = 0

      if (couponUsed.enabled) {
        orderRest =
          couponUsed.type === DiscountCouponType.FixedAmount
            ? data.payload.value - couponUsed.value >= 0
              ? data.payload.value - couponUsed.value
              : 0
            : parseFloat(`${data.payload.value}`) -
              parseFloat(`${(data.payload.value * couponUsed.value) / 100}`)
      }

      yield put(discountCouponSuccess(response.data, orderRest))
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError
      yield put(
        notificationOpenRequest(
          'Atenção',
          (error.response.data as string[])?.join(', '),
          'error',
          4000,
        ),
      )
    }
  }
}
