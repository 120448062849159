import axios, { AxiosError } from 'axios'
import { call, put, select, delay } from 'redux-saga/effects'
import moment from 'moment'
import { Cookies } from 'react-cookie'

import Cookie from '../../../tools/cookie'
import api from '../../../services/api'
import history from '../../../routes/history'
import {
  registerFailure,
  preRegisterFailure,
  loginSuccess,
  loginFailure,
  getRole,
  activateAccountSuccess,
  activateAccountFailure,
  sendActivationLinkSuccess,
  sendActivationLinkFailure,
  changePasswordSuccess,
  changePasswordFailure,
  updateProfileSuccess,
  updateProfileFailure,
  completeAccountSuccess,
  avatarSuccess,
  avatarFailure,
  completeAccountFailure,
  recoveryByEmailSuccess,
  recoveryByEmailFailure,
  logoutRequest,
  profileSuccess,
  profileFailure,
} from './actions'
import { schedule } from '../Schedule/actions'
import { notificationCloseRequest, notificationOpenRequest } from '../Notification/actions'
import { Error } from '../../../types/Error'
import { Auth, User } from './types'

const cookies = new Cookies()

export function* register(action: any): any {
  try {
    yield call(api.post, `/user/${action.payload.role}/register`, action.payload.user)
    yield put(notificationCloseRequest())
    yield call(history.push, `/verify-email/${action.payload.role}`)
  } catch (error) {
    yield put(
      notificationOpenRequest(
        'Atenção',
        'Há erros ou campos obrigatórios não preenchidos. Verifique os avisos nos campos abaixo.',
        'error',
        4000,
      ),
    )
    yield put(registerFailure(error))
  }
}

export function* pre_register(action: any): any {
  try {
    yield call(api.post, `/user/${action.payload.role}/pre_register`, action.payload.user)
    yield call(history.push, `/verify-email/${action.payload.role}`)
  } catch (error) {
    yield put(
      notificationOpenRequest(
        'Atenção',
        'Há erros ou campos obrigatórios não preenchidos. Verifique os avisos nos campos abaixo.',
        'error',
        4000,
      ),
    )
    yield put(preRegisterFailure(error))
  }
}

export function* login(data: any): any {
  try {
    // Loading Auth
    const auth = yield call(api.post, '/auth', data.payload)
    cookies.set('auth', JSON.stringify(auth.data), {
      path: '/',
      expires: moment().add(24, 'hours').toDate(),
    })

    const user = yield call(api.get, auth.data.user_iri, {
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${auth?.data?.token}`,
      },
    })

    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      yield put(getRole('student'))
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      yield put(getRole('teacher'))
    }

    // Retornando o Estado
    yield put(loginSuccess(auth.data, user.data))

    // Navegando até o dashboard
    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      window.location.href = '/home'
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      window.location.href = '/restricted-area/teacher'
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 401) {
          yield put(
            notificationOpenRequest('Atenção', error?.response?.data?.message, 'error', 3000),
          )
          yield put(
            loginFailure({
              ...error,
              'hydra:title': 'Atenção',
            }),
          )
          yield put(
            yield put(
              loginFailure({
                ...error,
                'hydra:title': 'Atenção',
              }),
            ),
          )
        }
      }
    }
  }
}

export function* login_with_facebook(data: any): any {
  try {
    // Loading Auth
    const auth = yield call(api.post, '/auth/facebook', data.payload)
    cookies.set('auth', JSON.stringify(auth.data), {
      path: '/',
      expires: moment().add(24, 'hours').toDate(),
    })

    const user = yield call(api.get, auth.data.user_iri, {
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${auth?.data?.token}`,
      },
    })

    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      yield put(getRole('student'))
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      yield put(getRole('teacher'))
    }

    // Retornando o Estado
    yield put(loginSuccess(auth.data, user.data))

    // Navegando até o dashboard
    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      window.location.href = '/home'
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      window.location.href = '/restricted-area/teacher'
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 401) {
          yield put(
            notificationOpenRequest('Atenção', error?.response?.data?.message, 'error', 3000),
          )
          yield put(
            loginFailure({
              ...error,
              'hydra:title': 'Atenção',
            }),
          )
          yield put(
            yield put(
              loginFailure({
                ...error,
                'hydra:title': 'Atenção',
              }),
            ),
          )
        }
      }
    }
  }
}

export function* login_with_google(data: any): any {
  try {
    // Loading Auth
    const auth = yield call(api.post, '/auth/google', data.payload)
    cookies.set('auth', JSON.stringify(auth.data), {
      path: '/',
      expires: moment().add(24, 'hours').toDate(),
    })

    const user = yield call(api.get, auth.data.user_iri, {
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${auth?.data?.token}`,
      },
    })

    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      yield put(getRole('student'))
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      yield put(getRole('teacher'))
    }

    // Retornando o Estado
    yield put(loginSuccess(auth.data, user.data))

    // Navegando até o dashboard
    if (user.data.roles.find((r: string) => r === 'ROLE_STUDENT')) {
      window.location.href = '/home'
    } else if (user.data.roles.find((r: string) => r === 'ROLE_TEACHER')) {
      window.location.href = '/restricted-area/teacher'
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 401) {
          yield put(
            notificationOpenRequest('Atenção', error?.response?.data?.message, 'error', 3000),
          )
          yield put(
            loginFailure({
              ...error,
              'hydra:title': 'Atenção',
            }),
          )
          yield put(
            yield put(
              loginFailure({
                ...error,
                'hydra:title': 'Atenção',
              }),
            ),
          )
        }
      }
    }
  }
}

export function logout(): any {
  try {
    localStorage.clear()
    Cookie.delete('auth')
    Cookie.delete('user')
    window.location.href = '/login'
  } catch (error) {
    console.error('error ao efetuar')
  }
}

export function* recoveryByEmail(data: any): any {
  try {
    yield call(api.post, '/password_recoveries', data.payload)
    yield put(recoveryByEmailSuccess())
    yield put(
      notificationOpenRequest(
        'Sucesso',
        'Você receberá um link para redefinir sua senha no seu e-mail',
        'success',
        1000,
      ),
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            recoveryByEmailFailure({
              'hydra:title': 'Atenção',
              'hydra:description': 'Error ao gerar o token de recuperação de senha',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            recoveryByEmailFailure({
              'hydra:title': 'Atenção',
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* getProfile(data: any): any {
  try {
    const response = yield call(api.get, data.payload.userId)
    yield put(profileSuccess(response.data))
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        yield put(
          profileFailure({
            'hydra:title': error?.response?.data?.['hydra:title'],
            'hydra:description': error?.response?.data?.['hydra:description'],
            status: error?.response?.status,
            violations: error?.response?.data?.violations,
          }),
        )
      }
    }
  }
}

export function* updateProfile(data: any): any {
  try {
    const response = yield call(api.patch, `${data.payload.id}`, data.payload.user, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    yield put(updateProfileSuccess(response.data))
    yield put(
      notificationOpenRequest(
        'Sucesso',
        'Seu cadastro foi realizado com sucesso!',
        'success',
        1000,
      ),
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            updateProfileFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao atualizar o cadastro',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else if (error?.response?.status === 422) {
          yield put(
            notificationOpenRequest(
              'Atenção',
              'Há erros ou campos obrigatórios não preenchidos. Verifique os avisos nos campos abaixo.',
              'error',
              4000,
            ),
          )
          yield put(
            updateProfileFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            updateProfileFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* complete_account(data: any): any {
  try {
    const params = new URL(window.location.href).searchParams
    const redirect = params.get('redirect')
    const teacherId = params.get('teacher')
    const scheduleId = params.get('schedule')
    const datetimeAvailable = params.get('datetime-available')

    const response = yield call(
      api.patch,
      `user/${data.payload.role}/${data.payload.id}/complete_account`,
      {
        ...data.payload.user,
        accountComplete: true,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    )
    yield put(completeAccountSuccess(response.data))
    yield put(
      notificationOpenRequest(
        'Sucesso',
        'Seu cadastro foi realizado com sucesso!',
        'success',
        1000,
      ),
    )

    if (redirect) {
      const teacher = yield call(api.get, `/teachers/${teacherId.replace(/[^0-9]/g, '')}`)
      yield put(schedule(teacher.data, scheduleId, moment(datetimeAvailable)))
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      console.log(error)
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            completeAccountFailure({
              'hydra:title': 'Atenção',
              'hydra:description': 'Ocorreu um error atualizar o seu cadastro',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            completeAccountFailure({
              'hydra:title': 'Atenção',
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* updateAvatar(data: any): any {
  try {
    const response = yield call(api.patch, `${data.payload.id}`, data.payload.user, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    yield put(avatarSuccess(response.data))
  } catch (e) {
    notificationOpenRequest('Error', 'Error ao atualizar o avatar', 'error', 1000)
    yield put(avatarFailure(e))
  }
}

export function* changePassword(data: any): any {
  try {
    const response = yield call(
      api.patch,
      data.payload.token ? `/password_reset/${data.payload.token}` : data.payload.id,
      {
        password: data.payload.password,
        passwordConfirmation: data.payload.passwordConfirmation,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    )
    yield put(changePasswordSuccess(response.data))
    yield put(
      notificationOpenRequest('Sucesso', 'Seu senha foi realizado com sucesso!', 'success', 3000),
    )
    if (data.payload.token) {
      yield call(history.push, '/login')
    } else {
      yield delay(1000)
      yield put(logoutRequest())
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 404) {
          yield put(
            changePasswordFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao alterar a senha',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            changePasswordFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
  }
}

export function* delete_account(data: any): any {
  try {
    yield call(api.request, {
      method: 'DELETE',
      url: '/delete_account',
      data: {
        password: data.payload.password,
      },
    })
    yield put(logoutRequest())
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        yield put(
          notificationOpenRequest(
            'Atenção',
            error?.response?.data?.['hydra:description'],
            'error',
            3000,
          ),
        )
        yield put(
          changePasswordFailure({
            'hydra:title': error?.response?.data?.['hydra:title'],
            'hydra:description': error?.response?.data?.['hydra:description'],
            status: error?.response?.status,
            violations: error?.response?.data?.violations,
          }),
        )
      }
    }
  }
}

export function* activateAccount(data: any): any {
  try {
    yield call(api.post, `/user/${data.payload.id}/activate`, {
      token: data.payload.token,
    })
    yield put(activateAccountSuccess())
  } catch (err) {
    console.error('Ocorreu um erro não enviar a página de verificação de e-mail')
    yield put(activateAccountFailure(err))
  }
}

export function* sendActivationLink(data: any): any {
  try {
    yield call(api.post, `/users/send_activation_link`, {
      email: data.payload.email,
    })
    yield put(sendActivationLinkSuccess())
  } catch (err) {
    console.error(err)
    yield put(sendActivationLinkFailure(err))
  }
}

export function* isUserAuthenticated() {
  try {
    if (!Cookie.get('auth') && !Cookie.get('user')) unauthorized()

    yield put(
      loginSuccess(JSON.parse(Cookie.get('auth')) as Auth, JSON.parse(Cookie.get('user')) as User),
    )
    yield call(history.push, '/home')
  } catch (error) {
    console.warn(error)
  }
}

export function* refreshUser() {
  try {
    const getAuth = (state) => state.auth
    const currentAuth = yield select(getAuth)

    const user = yield call(api.get, currentAuth.auth.user_iri)
    yield put(loginSuccess(currentAuth.auth, user.data))
  } catch (e) {
    console.error('Error ao atualizar Profile')
  }
}

function* unauthorized() {
  yield call(history.push, '/login')
}
