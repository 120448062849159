import { action } from 'typesafe-actions'
import { Review, ReviewTypes } from './types'

/**
 * GetAll reviews request
 */
export const getAllReviewRequest = (teacherId?: number, itemsPerPage?: number) =>
  action(ReviewTypes.REVIEW_REQUEST_GET_ALL, { teacherId, itemsPerPage })

/**
 * GetAll reviews success
 */
export const getAllReviewSuccess = (data: any) =>
  action(ReviewTypes.REVIEW_SUCCESS_GET_ALL, { data })

/**
 * GetAll reviews failure
 */
export const getAllReviewFailure = (error: any) =>
  action(ReviewTypes.REVIEW_FAILURE_GET_ALL, { ...error })

/**
 * Find review request
 */
export const findReviewRequest = (id: number, page = 1, status = 1) =>
  action(ReviewTypes.REVIEW_REQUEST_FIND, { id, page, status })

/**
 * Find review success
 */
export const findReviewSuccess = (data: any) => action(ReviewTypes.REVIEW_SUCCESS_FIND, { data })

/**
 * Find review failure
 */
export const findReviewFailure = (error: any) =>
  action(ReviewTypes.REVIEW_FAILURE_FIND, { ...error })

/**
 * Add review request
 */
export const addReviewRequest = (
  order: string,
  content: string,
  stars: number,
  wasTeacherPresent: boolean,
) => action(ReviewTypes.REVIEW_REQUEST_ADD, { order, content, stars, wasTeacherPresent })

/**
 * Add review success
 */
export const addReviewSuccess = (review: Review) =>
  action(ReviewTypes.REVIEW_SUCCESS_ADD, { review })

/**
 * Add review failure
 */
export const addReviewFailure = (error: any) => action(ReviewTypes.REVIEW_FAILURE_ADD, { ...error })
