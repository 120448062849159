import React from 'react'

import {
  Grid,
  Container,
  Typography,
  Stack,
  Paper,
  Divider,
  Box,
  Button,
  Link,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CallMadeIcon from '@mui/icons-material/CallMade'

import { themeApp } from '../../../themes'
import { useAppDispatch, useAppSelector, useIsMounted } from '../../../hooks'
import * as SettingsActions from '../../../store/ducks/Settings/actions'

const Item = styled(Paper)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
  boxShadow: 'none',
}))

const Footer = () => {
  const navigate = useNavigate()
  const settings = useAppSelector((state) => state.settings)
  const isMounted = useIsMounted()
  const appDispatch = useAppDispatch()

  React.useEffect(() => {
    if (isMounted) {
      appDispatch(SettingsActions.getAllSettingsRequest())
    }
  }, [isMounted])

  const getSetting = (id: string) => settings.settings.find((r) => r.id === id)

  return (
    <footer>
      <Container maxWidth='xl'>
        <Grid
          container
          sx={{
            justifyContent: {
              xs: 'center',
              lg: 'inherit',
            },
            gap: {
              xs: '15px',
              lg: 0,
            },
          }}
        >
          <Grid
            item
            mb={2}
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              width: '100%',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                  xs: 'center',
                  lg: 'start',
                },
                fontFamily: 'monospace',
                letterSpacing: 0,
                textDecoration: 'none',
                flexGrow: 1,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                }}
                divider={
                  <Divider
                    orientation='vertical'
                    flexItem
                    color='#253d4e'
                    sx={{
                      mr: '22px',
                      ml: '22px',
                      height: 25,
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                      alignSelf: 'center',
                      borderWidth: 0.5,
                    }}
                  />
                }
              >
                <Item
                  sx={{
                    width: '100%',
                    flexDirection: {
                      xs: 'column',
                      md: 'row',
                    },
                    alignItems: {
                      xs: 'center',
                    },
                  }}
                >
                  <Typography
                    component='a'
                    href='/home'
                    sx={{
                      textDecoration: 'none',
                      marginRight: '10px',
                      fontWeight: 'bold',
                      color: '#253d4e',
                      fontSize: 32,
                    }}
                  >
                    EWA
                  </Typography>
                  <Typography
                    component='a'
                    href='/home'
                    sx={{
                      textDecoration: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#253d4e',
                      fontSize: {
                        sm: 20,
                        xs: 12,
                      },
                      marginTop: '5px',
                    }}
                  >
                    AULAS PARTICULARES DE <b style={{ marginLeft: 9 }}>INGLÊS</b>
                  </Typography>
                </Item>
                <Item
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    color: '#253d4e',
                    boxShadow: 'none',
                    marginBottom: {
                      xs: 2,
                      sm: 0,
                    },
                  }}
                >
                  <span>Siga-nos:</span>
                  <Typography
                    component='a'
                    href={getSetting('facebook_url')?.value}
                    target='_blank'
                    sx={{
                      color: 'inherit',
                      marginTop: '6px',
                    }}
                  >
                    <FacebookIcon />
                  </Typography>
                  <Typography
                    component='a'
                    href={getSetting('instagram_url')?.value}
                    target='_blank'
                    sx={{
                      color: 'inherit',
                      marginTop: '6px',
                    }}
                  >
                    <InstagramIcon />
                  </Typography>
                </Item>
              </Stack>
            </Typography>
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                  alignItems: 'center',
                  gap: 10,
                  marginRight: 10,
                  flexGrow: 1,
                },
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: {
                  xs: 'center',
                  lg: 'end',
                },
              }}
            >
              <Button
                onClick={() => navigate('/login/student')}
                variant='outlined'
                sx={{
                  color: '#253d4e',
                  display: 'block',
                  border: '1px solid #7bd7b7',
                  borderRadius: '20px',
                  padding: '7px 45px',
                  fontWeight: 'normal',
                  my: {
                    xs: 0,
                    sm: 2,
                  },
                  '&:hover': {
                    color: '#FFFFFF',
                    backgroundColor: `${themeApp.hoverColor}`,
                  },
                }}
              >
                ACESSO DO ALUNO
              </Button>
              <Button
                onClick={() => navigate('/login/teacher')}
                variant='outlined'
                sx={{
                  color: '#253d4e',
                  display: 'block',
                  border: '1px solid #7bd7b7',
                  borderRadius: '20px',
                  padding: '7px 30px',
                  fontWeight: 'normal',
                  my: {
                    xs: 0,
                    sm: 2,
                  },
                  '&:hover': {
                    color: '#FFFFFF',
                    backgroundColor: `${themeApp.hoverColor}`,
                  },
                  '&:last-child': {
                    xs: {
                      marginBottom: 0,
                    },
                    sm: {
                      marginBottom: '16px',
                    },
                  },
                }}
              >
                ACESSO DO PROFESSOR
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Item>
              <Link
                href='https://wa.me/+5511971325901'
                component='a'
                underline='none'
                sx={{
                  color: '#203849',
                }}
              >
                <Typography
                  display='flex'
                  alignItems='center'
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                      fontWeight: 600,
                    },
                  }}
                >
                  <WhatsAppIcon sx={{ mx: 1, color: 'primary.main' }} />
                  +55 11 97132 5901
                </Typography>
              </Link>
            </Item>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} display='flex' flexDirection='column'>
            <Link
              href='/como-funciona'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                COMO FUNCIONA
              </Typography>
            </Link>
            <Link
              href='/como-fazer-aula-experimental'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                COMO AGENDAR SUA AULA
              </Typography>
            </Link>
            <Link
              href='/como-anunciar-suas-aulas'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                COMO ANUNCIAR SUAS AULAS
              </Typography>
            </Link>
            <Link
              href='/valores'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                VALORES
              </Typography>
            </Link>
            <Link
              href='https://blog.aulasparticulares.trupeweb.com.br'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                BLOG
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} display='flex' flexDirection='column'>
            <Link
              href='/quem-somos'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                Quem somos
              </Typography>
            </Link>
            <Link
              href='/faq'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                FAQ - Perguntas Frequentes
              </Typography>
            </Link>
            <Link
              href='/depoimentos'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                Depoimentos
              </Typography>
            </Link>
            <Link
              href='/fale-conosco'
              component='a'
              underline='none'
              sx={{
                color: '#203849',
              }}
            >
              <Typography
                display='flex'
                alignItems='center'
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                <CallMadeIcon sx={{ mx: 1, color: 'primary.main' }} />
                Fale conosco
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            lg={2}
            display='flex'
            flexDirection='column'
            alignItems='start'
            sx={{
              alignItems: {
                xs: 'center',
                sm: 'start',
              },
            }}
          >
            <Link
              href='/termo-uso'
              component='a'
              underline='none'
              fontSize={14}
              color='#253d4e'
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  fontWeight: 600,
                },
              }}
            >
              Termo de uso
            </Link>
            <Link
              href='/politica-privacidade'
              component='a'
              underline='none'
              fontSize={14}
              color='#253d4e'
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  fontWeight: 600,
                },
              }}
            >
              Política de privacidade
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              textAlign: { xs: 'center', lg: 'start' },
            }}
          >
            <Typography fontSize={10} color='#253d4e'>
              @2022 - Alpha BM
            </Typography>
            <Typography fontSize={10} color='#253d4e'>
              {process.env.REACT_APP_WEBSITE_NAME}
            </Typography>
            <Typography fontSize={10} mt={3} color='#253d4e'>
              Criação de sites: Trupe Agência Criativa
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
