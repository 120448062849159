import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { TrafficLightStatusChange } from '../../../types/TrafficLights'

/**
 * It returns an action object with a type of `TrafficLightTypes.GET_STATUS_CHANGES_REQUEST`
 */
export const getCollection = (page = 1) =>
  action(types.TrafficLightTypes.GET_STATUS_CHANGES_REQUEST, { page })

export const resetCollection = () => action(types.TrafficLightTypes.RESET_STATUS_CHANGE_COLLECTION)

export const getLatestTrafficLightStatusChange = () =>
  action(types.TrafficLightTypes.GET_LATEST_CHANGE_REQUEST)

/**
 * It takes an array of status changes and returns an action with the type GET_STATUS_CHANGES_SUCCESS and
 * the data payload
 * @param {TrafficLightStatusChange[]} data - TrafficLightStatusChange[] - this is the data that will be passed to the
 * reducer.
 */
export const getTrafficLightsStatusChangesCollectionSuccess = (data: TrafficLightStatusChange[]) =>
  action(types.TrafficLightTypes.GET_STATUS_CHANGES_SUCCESS, { data })

/**
 * It returns an action object with the type of `TrafficLightTypes.GET_STATUS_CHANGES_FAILURE` and the
 * error object
 * @param {Error} error - Error - The error object that was thrown
 */
export const getTrafficLightsStatusChangesCollectionFailure = (error: Error) =>
  action(types.TrafficLightTypes.GET_STATUS_CHANGES_FAILURE, { error })

/**
 * It takes an array of status changes and returns an action with the type GET_STATUS_CHANGES_SUCCESS and
 * the data payload
 * @param {TrafficLightStatusChange} data - TrafficLightStatusChange[] - this is the data that will be passed to the
 * reducer.
 */
export const getLatestTrafficStatusChangeCollectionSuccess = (data: TrafficLightStatusChange) =>
  action(types.TrafficLightTypes.GET_LATEST_CHANGE_SUCCESS, { data })

/**
 * It returns an action object with the type of `TrafficLightTypes.GET_LATEST_CHANGE_FAILURE` and the
 * error object
 * @param {Error} error - Error - The error object that was thrown
 */
export const getLatestTrafficStatusChangeCollectionFailue = (error: Error) =>
  action(types.TrafficLightTypes.GET_LATEST_CHANGE_FAILURE, { error })
