import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import {
  getTrafficLightsStatusChangesCollectionSuccess,
  getTrafficLightsStatusChangesCollectionFailure,
  getLatestTrafficStatusChangeCollectionSuccess,
  getLatestTrafficStatusChangeCollectionFailue,
} from './actions'

export function* get_all_traffic_lights_status_changes(request: any): any {
  try {
    const page = request.payload.page
    const response = yield call(api.get, `/traffic_light_status_changes?page=${page}`)
    yield put(getTrafficLightsStatusChangesCollectionSuccess(response.data))
  } catch (error) {
    yield put(getTrafficLightsStatusChangesCollectionFailure(error))
  }
}

export function* get_latest_traffic_lights_status_change(): any {
  try {
    const response = yield call(api.get, '/traffic_light_status_changes?itemsPerPage=1')
    yield put(getLatestTrafficStatusChangeCollectionSuccess(response.data))
  } catch (error) {
    yield put(getLatestTrafficStatusChangeCollectionFailue(error))
  }
}
