import { Reducer } from 'redux'
import { TrafficLightsState, TrafficLightTypes } from './types'

const INITIAL_STATE: TrafficLightsState = {
  all: [],
  latest: null,
  error: null,
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<TrafficLightsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrafficLightTypes.GET_STATUS_CHANGES_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case TrafficLightTypes.RESET_STATUS_CHANGE_COLLECTION:
      return {
        ...state,
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
        all: [],
      }
    case TrafficLightTypes.GET_STATUS_CHANGES_SUCCESS:
      state.all.push(...action.payload.data['hydra:member'])
      return {
        ...state,
        error: null,
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case TrafficLightTypes.GET_STATUS_CHANGES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }
    case TrafficLightTypes.GET_LATEST_CHANGE_REQUEST:
      return {
        ...state,
        latest: null,
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case TrafficLightTypes.GET_LATEST_CHANGE_SUCCESS:
      return {
        ...state,
        latest: action.payload.data['hydra:member'][0],
        error: null,
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case TrafficLightTypes.GET_LATEST_CHANGE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }
    default:
      return state
  }
}

export default reducer
