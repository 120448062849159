/* A cookie manager. */
const Cookie = {
  /* Setting a cookie. */
  set: (cname: string, cvalue: string, exdays: number) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = `expires=${d.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  },
  /* Getting the cookie value. */
  get: (cname: string) => {
    let nameEQ = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  /* Deleting the cookie. */
  delete: (cname: string) => {
    document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  },
  /* Checking if the cookie exists. */
  check: (cname: string) => {
    return new RegExp(`^(.*;)?\\s*${cname}\\s*=`).test(document.cookie)
  },
}

export default Cookie
