import { TrafficLightStatusChange } from '../../../types/TrafficLights'

/**
 * Action Types
 */
export enum TrafficLightTypes {
  GET_STATUS_CHANGES_REQUEST = '@traffic_lights/get_collection/request',
  GET_STATUS_CHANGES_SUCCESS = '@traffic_lights/get_collection/success',
  GET_STATUS_CHANGES_FAILURE = '@traffic_lights/get_collection/failure',
  GET_LATEST_CHANGE_REQUEST = '@traffic_lights/get_latest/request',
  GET_LATEST_CHANGE_SUCCESS = '@traffic_lights/get_latest/success',
  GET_LATEST_CHANGE_FAILURE = '@traffic_lights/get_latest/failure',
  RESET_STATUS_CHANGE_COLLECTION = '@traffic_lights/reset_collection',
}

/**
 * State type
 */
export interface TrafficLightsState {
  readonly all: TrafficLightStatusChange[]
  readonly latest: TrafficLightStatusChange
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
