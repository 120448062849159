import { call, put } from 'redux-saga/effects'
import axios, { AxiosError } from 'axios'
import moment from 'moment'

import api from '../../../services/api'
import history from '../../../routes/history'
import { Error } from '../../../types/Error'
import {
  purchaseSuccess,
  purchaseFailure,
  paymentSuccess,
  paymentFailure,
  calculateInstallmentsSuccess,
  calculateInstallmentsFailure,
  transactionSuccess,
  transactionFailure,
  getAllScheduleSuccess,
  getAllScheduleFailure,
  newScheduleAvailableFailure,
  newScheduleAvailableSuccess,
  getAllScheduleRequest,
  deleteScheduleFailure,
  deleteScheduleSuccess,
  discountOrdersSuccess,
  discountCouponSuccess,
  discountCouponFailure,
  paymentWaiting,
  myScheduleRequest,
} from './actions'
import { notificationOpenRequest } from '../Notification/actions'
import { Order } from '../Orders/types'
import { CreditCard } from '../../../types/CreditCard'
import { refreshUser } from '../Auth/actions'
import { appSelect } from '../../../hooks/useAppSelect'
import { getAllOrdersRequest } from '../Orders/actions'
import { DiscountCoupon } from '../../../types/DiscountCoupon'
import { DiscountCouponType } from '../../../enums/DiscountCouponType'

export function* getAllSchedule(): any {
  try {
    const response = yield call(api.get, '/schedules')
    yield put(getAllScheduleSuccess(response.data['hydra:member']))
  } catch (error) {
    yield put(getAllScheduleFailure(error))
  }
}

export function* mySchedule(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/my_schedules?start[after]=${data?.payload?.start} 00:00:00&end[before]=${data?.payload?.end} 23:59:59&pagination=false`,
    )
    yield put(getAllScheduleSuccess(response.data['hydra:member']))
  } catch (error) {
    yield put(getAllScheduleFailure(error))
  }
}

/* A saga that is called when the user clicks on the button "Agendar aula
experimental" */
export function* schedule(data: any): any {
  try {
    const user = yield appSelect((state) => state.auth.user)
    if (!user.accountComplete) {
      yield call(
        history.push,
        `/restricted-area/student/my-cadastre?redirect=/purchase&teacher=${
          data.payload.teacher['@id']
        }&schedule=${data.payload.scheduleId}&datetime-available=${moment(
          data.payload.dateTimeAvailable,
        ).format('DD/MM/YYYY')}`,
      )
      yield put(
        notificationOpenRequest(
          'Atenção',
          'Complete seu cadastro para realizar a compra da aula experimental. Após salvar você será redirecionado à página de checkout.',
          'error',
          20000,
        ),
      )
      return
    }
    yield call(history.push, '/agendar-aula-experimental/purchase')
  } catch (error) {
    console.log(error)
  }
}

/* A saga that is called when the user clicks on the button "Agendar aula
experimental" */
export function* purchase(): any {
  try {
    //const response = yield call(api.get, '/register')
    yield put(purchaseSuccess(null))
    yield put(refreshUser())
    yield call(history.push, '/agendar-aula-experimental/payment')
  } catch (error) {
    yield put(purchaseFailure(error))
  }
}

/* A saga that is called when the user clicks on the button "Agendar aula
experimental" */
export function* payment(data: any): any {
  try {
    let payload: {
      schedule?: number
      paymentMethod?: string
      creditCard?: CreditCard
      discountOrders?: []
      discountCoupon?: string
    } = {
      schedule: data.payload?.scheduleId,
      paymentMethod: data.payload?.paymentMethod,
    }

    if (data.payload?.creditCard) payload.creditCard = data.payload?.creditCard
    if (data.payload?.discountCoupon) payload.discountCoupon = data.payload?.discountCoupon
    if (data.payload?.discountOrders && data.payload?.discountOrders.length > 0)
      payload.discountOrders = data.payload?.discountOrders

    if (!payload?.creditCard?.number) {
      delete payload.creditCard
    }
    const response = yield call(api.post, '/orders', payload)

    if (response.data.amount === 0) {
      yield put(paymentSuccess(response.data))
      yield put(transactionSuccess(response.data.transaction))
      yield put(refreshUser())
      yield call(history.push, '/agendar-aula-experimental/confirm-payment')
    }

    if (
      response.data.transaction?.status === 'paid' ||
      response.data.transaction?.status === 'analyzing'
    ) {
      yield put(paymentSuccess(response.data))
      yield put(transactionSuccess(response.data.transaction))
      yield put(refreshUser())
      yield call(history.push, '/agendar-aula-experimental/confirm-payment')
    }

    if (response.data.transaction?.status === 'waiting_payment') {
      yield put(paymentWaiting(response.data))
    }

    if (
      response.data.transaction?.status === 'refused' ||
      response.data.transaction?.status === 'default'
    ) {
      throw new ReferenceError({
        ...response.data,
      })
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error.response.status === 422) {
          const schedulesError = error.response.data?.violations?.filter((violation) => {
            return violation.propertyPath === 'schedules'
          })
          if (schedulesError.length) {
            yield put(notificationOpenRequest('Error', schedulesError[0].message, 'error', 4000))
          }
        } else if (error.response.status === 400) {
          yield put(
            transactionFailure({
              status: 'refused',
              message: error?.response?.data?.['hydra:description'],
            }),
          )
          yield put(
            paymentFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
        yield put(
          paymentFailure({
            'hydra:title': error?.response?.data?.['hydra:title'],
            'hydra:description': error?.response?.data?.['hydra:description'],
            status: error?.response?.status,
            violations: error?.response?.data?.violations,
          }),
        )
      }
    }
  }
}

/* This function is responsible for calculating the installments that the user will
have to pay for the class. */
export function* calculate_installments(data: any): any {
  try {
    const response = yield call(api.get, `/calculate_installments?amount=${data.payload.amount}`)
    yield put(calculateInstallmentsSuccess(data.payload.amount, response.data.installments))
  } catch (error) {
    yield put(calculateInstallmentsFailure(error))
  }
}

/* A saga that is called when the user clicks on the button "Confirmar
pagamento" */
export function* confirm_payment(): any {
  try {
    /*
    const currentUser: AuthState = yield select(getUser)
    const currentState: ScheduleState = yield select(getState)

    currentUser.user.orders.push({
      id: Math.random(),
      schedule: {
        start: moment(currentState.dateTimeAvailable).format('DD/MM/YYYY HH:mm:ss').toString(),
        end: moment(currentState.dateTimeAvailable)
          .subtract(-1, 'hour')
          .format('DD/MM/YYYY HH:mm:ss')
          .toString(),
        author: {
          name: currentState.teacher.name,
          address: { ...currentState.teacher.address },
        },
      },
    })

    currentUser.user.schedules.push({
      start: moment(currentState.dateTimeAvailable).format('YYYY-MM-DD HH:mm:ss').toString(),
      end: moment(currentState.dateTimeAvailable)
        .subtract(+1, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString(),
      available: true,
    })
    */
    //const response = yield call(api.get, '/register')
    //yield put(paymentSuccess(null))
  } catch (error) {
    yield put(paymentFailure(error))
  }
}

/* This saga is responsible for calculating the discount that the user will have in
the next class. */
export function* discount_orders(data: any): any {
  try {
    const response = yield call(api.get, `/orders?status=2&itemsPerPage=9999`)
    const listOrders = response.data['hydra:member'] as Order[]

    let orderUsed = []
    let orderRest = 0
    let availableCredit = data.payload.availableCredit
    let totAmount = 0

    /*
      Calculando o total de desconto
      Calculando o valor total de descontos disponíveis e o valor total de
descontos usados
    */
    let discounts = listOrders.reduce((c, item) => {
      if (item['@id'] && item.totalAmount)
        c.push({
          id: item['@id'],
          amount: item.totalAmount,
        })

      if (!(totAmount === data.payload.classValue || totAmount > data.payload.classValue)) {
        totAmount += item.totalAmount
        orderUsed.push(item['@id'])
      }

      return c
    }, new Array<any>())

    console.log(
      'Orders:',
      discounts,
      'Total acumulado',
      availableCredit,
      'OrdersUsed:',
      orderUsed,
      'Rest:',
      Math.abs(availableCredit - data.payload.classValue),
    )

    if (availableCredit - data.payload.classValue < 0) {
      orderRest = Math.abs(availableCredit - data.payload.classValue)
      availableCredit = 0
    } else {
      availableCredit -= data.payload.classValue
    }

    yield put(discountOrdersSuccess(orderUsed, orderRest, availableCredit))
  } catch (error) {
    console.log(error)
  }
}

export function* discount_coupon(data: any): any {
  try {
    const response = yield call(api.get, `/coupons/validate/${data.payload.coupon}`)
    if (response.status === 200) {
      let couponUsed = response.data as DiscountCoupon
      let orderRest = 0

      if (couponUsed.enabled) {
        orderRest =
          couponUsed.type === DiscountCouponType.FixedAmount
            ? data.payload.classValue - couponUsed.value
            : parseFloat(`${data.payload.classValue}`) -
              parseFloat(`${(data.payload.classValue * couponUsed.value) / 100}`)

        console.log(
          'classValue:',
          data.payload.classValue,
          'couponUsed:',
          couponUsed.value,
          'pDesconto:',
          (data.payload.classValue * couponUsed.value) / 100,
          'Total:',
          (
            parseFloat(`${data.payload.classValue}`) -
            parseFloat(`${(data.payload.classValue * couponUsed.value) / 100}`)
          ).toFixed(2),
        )
      }

      yield put(discountCouponSuccess(response.data, orderRest))
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError
      yield put(
        notificationOpenRequest(
          'Atenção',
          (error.response.data as string[])?.join(', '),
          'error',
          4000,
        ),
      )
    }
  }
}

/* A saga that is called when the user clicks on the button "Agendar aula
experimental" */
export function* new_schedule_available(data: any): any {
  try {
    const response = yield call(api.post, '/schedules/batch', data.payload.scheduleAvailable)
    yield put(newScheduleAvailableSuccess())
    if (data.payload?.mySchedule) {
      yield put(myScheduleRequest(data.payload?.mySchedule?.start, data.payload?.mySchedule?.end))

      const sh = response?.data?.schedules
      const err = response?.data?.errors as Error[]

      let errText = ''
      if (sh.length > 0) {
        errText = sh.length > 1 ? `Os horários foram adicionados!` : `O horário foi adicionado!`
      } else if (sh.length === 0 && err.length > 0) {
        let errArr = []
        err.forEach((e) => e.violations.forEach((violation) => errArr.push(violation.title)))
        errText = errArr.join('\n')
      }

      yield put(
        notificationOpenRequest(
          sh.length === 0 && err.length > 0 ? 'Atenção' : 'Informação',
          errText,
          sh.length === 0 && err.length > 0 ? 'error' : 'success',
          4000,
        ),
      )
    } else {
      yield put(getAllScheduleRequest())
      yield put(getAllOrdersRequest())
      yield put(notificationOpenRequest('Informação', 'O horário foi adicionado!', 'success', 4000))
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error?.response?.status === 400 || error?.response?.status === 403) {
        yield put(
          notificationOpenRequest('Error', 'Você precisa preencher todos os campos', 'error', 4000),
        )
        yield put(
          newScheduleAvailableFailure({
            'hydra:title': 'Error',
            'hydra:description': 'Você precisa preencher todos os campos',
            status: error?.response?.status,
            violations: [],
          }),
        )
      } else if (error?.response?.status === 404) {
        yield put(
          notificationOpenRequest(
            'Error',
            'Ocorreu um error ao cadastrar o horário',
            'error',
            4000,
          ),
        )
        yield put(
          newScheduleAvailableFailure({
            'hydra:title': 'Error',
            'hydra:description': 'O horário selecionado conflita com um horário já cadastrado',
            status: error?.response?.status,
            violations: [],
          }),
        )
      } else if (error?.response?.status === 422) {
        yield put(
          notificationOpenRequest(
            'Atenção',
            error?.response?.data?.['hydra:description'],
            'error',
            4000,
          ),
        )
        yield put(
          newScheduleAvailableFailure({
            'hydra:title': error?.response?.data?.['hydra:title'],
            'hydra:description': error?.response?.data?.['hydra:description'],
            status: error?.response?.status,
            violations: error?.response?.data?.violations,
          }),
        )
      } else {
        yield put(
          newScheduleAvailableFailure({
            'hydra:title': error?.response?.data?.['hydra:title'],
            'hydra:description': error?.response?.data?.['hydra:description'],
            status: error?.response?.status,
            violations: error?.response?.data?.violations,
          }),
        )
      }
    }
  }
}

/* A saga that is called when the user clicks on the button "Excluir
horário" */
export function* delete_schedule_available(data: any): any {
  try {
    yield call(api.delete, `${data.payload.scheduleId}`)
    yield put(deleteScheduleSuccess())
    if (data.payload?.mySchedule)
      yield put(myScheduleRequest(data.payload?.mySchedule?.start, data.payload?.mySchedule?.end))
    else yield put(getAllScheduleRequest())
    yield put(
      notificationOpenRequest('Sucesso', 'O horário foi excluído com sucesso!', 'success', 4000),
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      yield put(
        notificationOpenRequest('Error', 'Ocorreu um erro não excluir o horário!', 'error', 4000),
      )
      yield put(
        deleteScheduleFailure({
          'hydra:title': error?.response?.data?.['hydra:title'],
          'hydra:description': error?.response?.data?.['hydra:description'],
          status: error?.response?.status,
          violations: error?.response?.data?.violations,
        }),
      )
    }
  }
}
