import { applyMiddleware, legacy_createStore, Store } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { AuthState } from './ducks/Auth/types'
import { TeacherState } from './ducks/Teacher/types'
import { SearchState } from './ducks/Search/types'
import { ScheduleState } from './ducks/Schedule/types'
import { ReviewState } from './ducks/Reviews/types'
import { InvitesState } from './ducks/Invites/types'
import { SubscriptionsState } from './ducks/Subscriptions/types'
import { SpecializationState } from './ducks/Specializations/types'
import { ModalityState } from './ducks/Modalities/types'
import { OrdersState } from './ducks/Orders/types'
import { NotificationState } from './ducks/Notification/types'
import { FAQState } from './ducks/FAQ/types'
import { TestimonialsState } from './ducks/Testimonials/types'
import { PrivacyPoliciesState } from './ducks/PrivacyPolicies/types'
import { TermsOfUseState } from './ducks/TermsOfUse/types'
import { ContactState } from './ducks/Contact/types'
import { DashboardNotificationState } from './ducks/DashboardNotifications/types'
import { BlogState } from './ducks/Blog/types'
import { SettingsState } from './ducks/Settings/types'
import { TrafficLightsState } from './ducks/TrafficLights/types'

import rootReducers from './ducks/rootReducers'
import rootSagas from './ducks/rootSagas'
import { ContentsState } from './ducks/Contents/types'

const persistConfig = {
  key: 'aulas-particulares-ingles-root',
  storage,
}

export interface ApplicationState {
  auth: AuthState
  teacher: TeacherState
  search: SearchState
  schedule: ScheduleState
  invites: InvitesState
  review: ReviewState
  subscriptions: SubscriptionsState
  specializations: SpecializationState
  modalities: ModalityState
  orders: OrdersState
  notification: NotificationState
  faq: FAQState
  testimonials: TestimonialsState
  privacyPolicies: PrivacyPoliciesState
  termsOfUse: TermsOfUseState
  contact: ContactState
  dashboardNotification: DashboardNotificationState
  blog: BlogState
  settings: SettingsState
  contents: ContentsState
  trafficLights: TrafficLightsState
}

const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducers)

const store: Store<ApplicationState> = legacy_createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
)
const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

sagaMiddleware.run(rootSagas)

export { store, persistor }
