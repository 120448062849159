import { combineReducers } from 'redux'

import auth from './Auth'
import teacher from './Teacher'
import search from './Search'
import schedule from './Schedule'
import invites from './Invites'
import review from './Reviews'
import subscriptions from './Subscriptions'
import specializations from './Specializations'
import modalities from './Modalities'
import orders from './Orders'
import notification from './Notification'
import faq from './FAQ'
import testimonials from './Testimonials'
import privacyPolicies from './PrivacyPolicies'
import termsOfUse from './TermsOfUse'
import contact from './Contact'
import dashboardNotification from './DashboardNotifications'
import blog from './Blog'
import settings from './Settings'
import contents from './Contents'
import trafficLights from './TrafficLights'

export default combineReducers({
  teacher,
  auth,
  search,
  schedule,
  review,
  invites,
  subscriptions,
  specializations,
  modalities,
  orders,
  notification,
  faq,
  testimonials,
  privacyPolicies,
  termsOfUse,
  contact,
  dashboardNotification,
  blog,
  settings,
  contents,
  trafficLights,
})
