import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import history from '../../../routes/history'
import { searchIndexSuccess, searchIndexFailure, searchFailure, searchSuccess } from './actions'
import { SearchTypes } from './types'

export function* searchIndex(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/teachers?itemsPerPage=${data.payload.itemsPerPage}&page=${data.payload.page}`,
    )
    const classValuesResponse = yield call(api.get, '/schedule_prices')

    yield put(
      searchIndexSuccess(
        response.data['hydra:member'],
        {
          min: classValuesResponse.data.min,
          max: classValuesResponse.data.max,
        },
        response.data['hydra:totalItems'],
        data.payload.itemsPerPage,
        response.data['hydra:view'],
      ),
    )
  } catch (error) {
    yield put(searchIndexFailure(error))
  }
}

export function* search(data: any): any {
  const hourValues = {
    manha: ['06:00', '12:00'],
    tarde: ['12:00', '18:00'],
    noite: ['18:00', '24:00'],
    madrugada: ['00:00', '06:00'],
  }

  const params = new URLSearchParams({
    itemsPerPage: data.payload.itemsPerPage,
    page: data.payload.page,
  })

  if (!data.payload?.calendar?.length && data.payload?.hours?.length) {
    data.payload.hours.forEach((hour: string) => {
      if (hourValues[hour]) {
        params.append('schedules[]', hourValues[hour].join(', '))
      }
    })
  }

  if (data.payload.calendar && data.payload.calendar.length) {
    if (!data.payload?.hours?.length) {
      data.payload.calendar.forEach((date: string) => {
        params.append('schedules[]', date)
      })
    }

    if (data.payload?.hours?.length) {
      data.payload.calendar.forEach((date: string) => {
        data?.payload?.hours?.forEach((hour: string) => {
          if (hourValues[hour]) {
            params.append(
              'schedules[]',
              `${date} ${hourValues[hour][0]}, ${date} ${hourValues[hour][1]}`,
            )
          }
        })
      })
    }
  }

  if (data.payload?.specifications && data.payload.specifications.length) {
    data.payload.specifications.forEach((spec: string) => {
      params.append('specializations[]', spec)
    })
  }

  if (data.payload.teachingModalities && data.payload.teachingModalities) {
    data.payload.teachingModalities.forEach((modalityId: string) => {
      params.append('teachingModalities.modality[]', modalityId)
    })
  }

  if (data.payload?.native) {
    params.append('nativeSpeaker', 'true')
  }

  if (data.payload?.resident) {
    params.append('resident', 'true')
  }

  if (data.payload.classValues) {
    params.append(
      'classValue[between]',
      `${data.payload.classValues.min}..${data.payload.classValues.max}`,
    )
  }

  try {
    const response = yield call(api.get, `/teachers?${params.toString()}`)
    const classValuesResponse = yield call(api.get, '/schedule_prices')

    yield put(
      searchSuccess(
        response.data['hydra:member'],
        {
          min: classValuesResponse.data.min,
          max: classValuesResponse.data.max,
        },
        response.data['hydra:totalItems'],
        data.payload.itemsPerPage,
        response.data['hydra:view'],
      ),
    )
  } catch (error) {
    yield put(searchFailure(error))
  }
}

export function* searchHome(data: any): any {
  try {
    yield call(search, {
      type: SearchTypes.SEARCH_REQUEST,
      payload: data.payload,
    })

    const params = {
      horarios: data.payload.hours?.join(',') ?? null,
      nativo: data.payload.native ? 'sim' : null,
      residente: data.payload.resident ? 'sim' : null,
      valorMin: data.payload.classValues.min ?? null,
      valorMax: data.payload.classValues.max ?? null,
      pagina: data.payload.page ?? 1,
      modalidades: data.payload.teachingModalities?.join(',') ?? null,
      especializacoes: data.payload.specifications?.join(',') ?? null,
    }

    for (let param in { ...params }) {
      if (params[param] === '' || params[param] === null) {
        delete params[param]
      }
    }

    const urlParams = new URLSearchParams(params)

    data.payload.calendar?.forEach((date: string) => {
      urlParams.append('data[]', date)
    })

    yield call(history.push, `/aulas-de-ingles/?${urlParams.toString()}`, params)
  } catch (error) {
    console.log(error)
  }
}
