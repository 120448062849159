import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import {
  getAllReviewRequest,
  getAllReviewSuccess,
  findReviewSuccess,
  getAllReviewFailure,
  findReviewFailure,
  addReviewSuccess,
  addReviewFailure,
} from './actions'

export function* getAllReview(data: any): any {
  try {
    let urlPart = data.payload.teacherId ? `/reviews&teacher=${data.payload.teacherId}` : `/reviews`
    let url = data.payload.itemsPerPage
      ? `${urlPart}&pagination=true&itemsPerPage=${data.payload.itemsPerPage}`
      : `${urlPart}`
    const response = yield call(api.get, url)
    yield put(getAllReviewSuccess(response.data))
  } catch (error) {
    yield put(getAllReviewFailure(error))
  }
}

export function* findReview(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/reviews?teacher.id=${data.payload.id}&status[]=${data.payload.status}&page=${data.payload.page}&itemsPerPage=10`,
    )
    yield put(findReviewSuccess(response.data))
  } catch (error) {
    yield put(findReviewFailure(error))
  }
}

export function* addReview(data: any): any {
  try {
    const response = yield call(api.post, `/reviews`, {
      order: data.payload.order,
      content: data.payload.content,
      stars: Number(data.payload.stars),
      wasTeacherPresent: data.payload.wasTeacherPresent === 'true',
    })
    yield put(addReviewSuccess(response))
    yield put(getAllReviewRequest())
  } catch (error) {
    yield put(addReviewFailure(error))
  }
}
