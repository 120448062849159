import { action } from 'typesafe-actions'
import { Auth, User } from './types'
import { types } from '../index'
import { Error } from '../../../types/Error'

/**
 ############### Register ###############
*/
/**
 * It returns an action object with a type of REGISTER_INIT
 */
export const registerInit = () => action(types.AuthTypes.REGISTER_INIT)

/**
 * It returns an action object with the type of REGISTER_REQUEST and the role and
 * user as the payload
 * @param {string} role - string - The role of the user.
 * @param {User} user - User - this is the user object that we're going to pass to
 * the reducer.
 */
export const registerRequest = (role: string, user: User) =>
  action(types.AuthTypes.REGISTER_REQUEST, { role, user })

/**
 * It creates an action with the type of REGISTER_FAIL and the error object.
 * @param {any} error - any
 */
export const registerFailure = (error: any) => action(types.AuthTypes.REGISTER_FAIL, { ...error })

/**
 ############### Pre Register ###############
*/
/**
 * It returns an action object with a type of `PRE_REGISTER_INIT`
 */
export const preRegisterInit = () => action(types.AuthTypes.PRE_REGISTER_INIT)

/**
 * It returns an action object with the type of PRE_REGISTER_REQUEST and the user
 * object as the payload
 * @param role
 * @param {User} user - User - this is the type of the parameter that will be
 * passed to the action.
 */
export const preRegisterRequest = (role: string, user: User) =>
  action(types.AuthTypes.PRE_REGISTER_REQUEST, { role, user })

/**
 * It returns an action with the type `PRE_REGISTER_FAIL` and the error object
 * @param {any} error - any
 */
export const preRegisterFailure = (error: any) =>
  action(types.AuthTypes.PRE_REGISTER_FAIL, { ...error })

/**
 ############### Complete Account ###############
*/
/**
 * It returns an action object with a type of `AuthTypes.COMPLETE_ACCOUNT_INIT`
 */
export const completeAccountInit = () => action(types.AuthTypes.COMPLETE_ACCOUNT_INIT)

/**
 * It returns an action object with a type of COMPLETE_ACCOUNT_REQUEST and a
 * payload of the role and user
 * @param {string} role - string - The role of the user.
 * @param id
 * @param {User} user - User - this is the user object that is returned from the
 * server.
 */
export const completeAccountRequest = (role: string, id: string, user: User) =>
  action(types.AuthTypes.COMPLETE_ACCOUNT_REQUEST, { role, id, user })

/**
 * It returns an action object with a type of COMPLETE_ACCOUNT_SUCCESS and a
 * payload of the user object
 * @param {User} user - User - this is the type of the payload.
 */
export const completeAccountSuccess = (user: User) =>
  action(types.AuthTypes.COMPLETE_ACCOUNT_SUCCESS, { user })

/**
 * It returns an action object with a type of COMPLETE_ACCOUNT_FAIL and an error
 * object
 * @param {any} error - any
 */
export const completeAccountFailure = (error: any) =>
  action(types.AuthTypes.COMPLETE_ACCOUNT_FAILURE, { error })

/**
 ############### Login ###############
*/
/**
 * It returns an action object with a type of LOGIN_INIT
 */
export const loginInit = () => action(types.AuthTypes.LOGIN_INIT)

/**
 * It returns an action object with a type of LOGIN_REQUEST and a payload of
 * username and password
 * @param {string} username - string, password: string
 * @param {string} password - string - This is the password that the user entered.
 */
export const loginRequest = (username: string, password: string) =>
  action(types.AuthTypes.LOGIN_REQUEST, { username, password })

/**
 * It returns an action object with a type of LOGIN_WITH_FACEBOOK_REQUEST and a payload of
 * token and a role
 * @param {string} token - string, password: string
 * @param {string} role - string - This is the password that the user entered.
 */
export const loginWithFacebookRequest = (token: string, role: string) =>
  action(types.AuthTypes.LOGIN_WITH_FACEBOOK_REQUEST, { token, role })

/**
 * It returns an action object with a type of LOGIN_WITH_GOOGLE_REQUEST and a payload of
 * token and a role
 * @param {string} token - string, password: string
 * @param {string} role - string - This is the password that the user entered.
 */
export const loginWithGoogleRequest = (token: string, role: string) =>
  action(types.AuthTypes.LOGIN_WITH_GOOGLE_REQUEST, { token, role })

/**
 * It returns an action object with the type of LOGIN_SUCCESS and the auth and user
 * objects as payload
 * @param {Auth} auth - Auth - This is the type of the auth object that we're
 * passing in.
 * @param {User} user - User - This is the user object that we get from the server.
 */
export const loginSuccess = (auth: Auth, user: User) =>
  action(types.AuthTypes.LOGIN_SUCCESS, { auth, user })

/**
 * It returns an action object with a type of LOGIN_FAIL and an error object
 * @param {any} error - any
 */
export const loginFailure = (error: any) => action(types.AuthTypes.LOGIN_FAIL, { ...error })
/**
 ############### Recovery By Email ###############
*/
export const recoveryByEmailInit = () => action(types.AuthTypes.RECOVERY_BY_EMAIL_INIT)
/**
 * It returns an action object with a type of RECOVERY_BY_EMAIL_REQUEST and a
 * payload of an object with a key of email and a value of the email argument
 * @param {string} email - string
 */
export const recoveryByEmailRequest = (email: string) =>
  action(types.AuthTypes.RECOVERY_BY_EMAIL_REQUEST, { email })
/**
 * It returns an action object with a type of RECOVERY_BY_EMAIL_SUCCESS
 */
export const recoveryByEmailSuccess = () => action(types.AuthTypes.RECOVERY_BY_EMAIL_SUCCESS)
/**
 * It creates an action with the type RECOVERY_BY_EMAIL_FAILURE and the error
 * object.
 * @param {Error} error - Error
 */
export const recoveryByEmailFailure = (error: Error) =>
  action(types.AuthTypes.RECOVERY_BY_EMAIL_FAILURE, { error })

/**
 ############### Change Password ###############
*/
/**
 * It returns an action object with a type of CHANGE_PASSWORD_INIT
 */
export const changePasswordInit = () => action(types.AuthTypes.CHANGE_PASSWORD_INIT)

/**
 * It returns an action object with the type `CHANGE_PASSWORD_REQUEST` and the
 * payload `{ id, password, passwordConfirmation }`
 * @param {string} id - The user's id
 * @param {string} password - string
 * @param {string} passwordConfirmation - string
 * @param token
 */
export const changePasswordRequest = (
  id: string,
  password: string,
  passwordConfirmation: string,
  token?: string,
) => action(types.AuthTypes.CHANGE_PASSWORD_REQUEST, { id, password, passwordConfirmation, token })

/**
 * It returns an action object with the type of CHANGE_PASSWORD_SUCCESS and the
 * user object
 * @param {User} user - User - the user object that was returned from the API
 */
export const changePasswordSuccess = (user: User) =>
  action(types.AuthTypes.CHANGE_PASSWORD_SUCCESS, { user })

/**
 * It returns an action object with the type of CHANGE_PASSWORD_FAILURE and the
 * error object
 * @param {Error} error - Error - The error that was thrown
 */
export const changePasswordFailure = (error: Error) =>
  action(types.AuthTypes.CHANGE_PASSWORD_FAILURE, { error })

/**
 ############### Delete ###############
*/

/**
 * It returns an action object with the type of DELETE_REQUEST and the password as
 * the payload
 * @param {string} password - string
 */
export const deleteRequest = (password: string) =>
  action(types.AuthTypes.DELETE_REQUEST, { password })

/**
 * It returns an action object with a type of DELETE_SUCCESS
 */
export const deleteSuccess = () => action(types.AuthTypes.DELETE_SUCCESS)

/**
 * It creates an action with the type of DELETE_FAILURE and the error object.
 * @param {Error} error - Error - this is the error object that is returned from
 * the API.
 */
export const deleteFailure = (error: Error) => action(types.AuthTypes.DELETE_FAILURE, { error })

/**
 ############### Profile ###############
*/
export const profileRequest = (userId: string) =>
  action(types.AuthTypes.PROFILE_REQUEST, { userId })

export const profileSuccess = (user: User) => action(types.AuthTypes.PROFILE_SUCCESS, { user })

export const profileFailure = (error: Error) => action(types.AuthTypes.PROFILE_FAILURE, { error })

/**
 * It takes a user object and returns an action object with a type of
 * PROFILE_REQUEST and a payload of the user object
 * @param id
 * @param {User} user - User - this is the type of the parameter that will be
 * passed to the action.
 */
export const updateProfileRequest = (id: string, user: User) =>
  action(types.AuthTypes.UPDATE_PROFILE_REQUEST, { id, user })

/**
 * It takes a user object and returns an action object with the type of
 * PROFILE_SUCCESS and the user object as the payload
 * @param {User} user - User
 */
export const updateProfileSuccess = (user: User) =>
  action(types.AuthTypes.UPDATE_PROFILE_SUCCESS, { user })

/**
 * It returns an action object with the type and payload.
 * @param {Error} error - Error
 */
export const updateProfileFailure = (error: Error) =>
  action(types.AuthTypes.UPDATE_PROFILE_FAILURE, { error })

/**
 ############### Avatar ###############
*/
/**
 * It returns an action object with a type of UPDATE_AVATAR_REQUEST and a payload
 * of an object with an id and user property
 * @param {string} id - The id of the user to update
 * @param {User} user - User - this is the user object that we're going to update
 */
export const avatarRequest = (id: string, user: User) =>
  action(types.AuthTypes.UPDATE_AVATAR_REQUEST, { id, user })

/**
 * It creates an action with the type UPDATE_AVATAR_SUCCESS and the user object.
 * @param {User} user - User
 */
export const avatarSuccess = (user: User) => action(types.AuthTypes.UPDATE_AVATAR_SUCCESS, { user })

/**
 * It returns an action object with the type and payload.
 * @param {Error} error - Error
 */
export const avatarFailure = (error: Error) =>
  action(types.AuthTypes.UPDATE_AVATAR_FAILURE, { error })

/**
 ############### Activate Account ###############
*/
/**
 * It returns an action object with the type ACTIVATE_ACCOUNT_REQUEST and the id
 * and token as the payload
 * @param {string} id - The user's id
 * @param {string} token - The token that was sent to the user's email address.
 */
export const activateAccountRequest = (id: string, token: string) =>
  action(types.AuthTypes.ACTIVATE_ACCOUNT_REQUEST, { id, token })

/**
 * It returns an action object with a type of ACTIVATE_ACCOUNT_SUCCESS
 */
export const activateAccountSuccess = () => action(types.AuthTypes.ACTIVATE_ACCOUNT_SUCCESS)

/**
 * It returns an action object with the type ACTIVATE_ACCOUNT_FAIL and the error
 * object
 * @param {any} error - any
 */
export const activateAccountFailure = (error: any) =>
  action(types.AuthTypes.ACTIVATE_ACCOUNT_FAIL, { error })

/**
 ############### Activation Link ###############
*/
/**
 * It returns an action object with the type `RE_SEND_ACTIVATION_LINK_REQUEST` and
 * the email as the payload
 * @param {string} email - string - The email address of the user
 */
export const sendActivationLinkRequest = (email: string) =>
  action(types.AuthTypes.RE_SEND_ACTIVATION_LINK_REQUEST, { email })

/**
 * It returns an action object with the type `RE_SEND_ACTIVATION_LINK_SUCCESS`
 */
export const sendActivationLinkSuccess = () =>
  action(types.AuthTypes.RE_SEND_ACTIVATION_LINK_SUCCESS)

/**
 * It returns an action object with the type `RE_SEND_ACTIVATION_LINK_FAIL` and the
 * error object
 * @param {any} error - any
 */
export const sendActivationLinkFailure = (error: any) =>
  action(types.AuthTypes.RE_SEND_ACTIVATION_LINK_FAIL, { error })

/**
 ############### CheckUserSession ###############
*/
/**
 * It returns an action object with a type of CHECK_USER_SESSION
 */
export const checkUserSessionSuccess = () => action(types.AuthTypes.CHECK_USER_SESSION)

/**
 ############### Refresh User ###############
*/
/**
 * It returns an action object with a type of `REFRESH_USER`
 */
export const refreshUser = () => action(types.AuthTypes.REFRESH_USER)

/**
 ############### Get Role ###############
*/
/**
 * It takes a string as an argument and returns an object with a type and a payload
 * @param {string} role - string
 */
export const getRole = (role: string) => action(types.AuthTypes.GET_ROLE, { role })

/**
 ############### Logout ###############
*/
/**
 * It returns an action object with the type of LOGOUT
 */
export const logoutRequest = () => action(types.AuthTypes.LOGOUT)
