import { Auth as AuthType } from '../../../types/Auth'
import { ValidationsError } from '../../../types/Error/Validations'
import { Token } from '../../../types/Token'
import { User as UserType } from '../../../types/User'

/**
 * Action Types
 */
export enum AuthTypes {
  REGISTER_INIT = '@auth/register/init',
  REGISTER_REQUEST = '@auth/register/request',
  REGISTER_SUCCESS = '@auth/register/success',
  REGISTER_FAIL = '@auth/register/failure',

  PRE_REGISTER_INIT = '@auth/pre-register/init',
  PRE_REGISTER_REQUEST = '@auth/pre-register/request',
  PRE_REGISTER_SUCCESS = '@auth/pre-register/success',
  PRE_REGISTER_FAIL = '@auth/pre-register/failure',

  COMPLETE_ACCOUNT_INIT = '@auth/complete-account/init',
  COMPLETE_ACCOUNT_REQUEST = '@auth/complete-account/request',
  COMPLETE_ACCOUNT_SUCCESS = '@auth/complete-account/success',
  COMPLETE_ACCOUNT_FAILURE = '@auth/complete-account/failure',

  LOGIN_INIT = '@auth/login/init',
  LOGIN_REQUEST = '@auth/login/request',
  LOGIN_SUCCESS = '@auth/login/success',
  LOGIN_FAIL = '@auth/login/failure',
  LOGIN_WITH_FACEBOOK_REQUEST = '@auth/login/facebook/request',
  LOGIN_WITH_GOOGLE_REQUEST = '@auth/login/google/request',

  PROFILE_REQUEST = '@auth/profile/request',
  PROFILE_SUCCESS = '@auth/profile/success',
  PROFILE_FAILURE = '@auth/profile/failure',

  UPDATE_PROFILE_REQUEST = '@auth/updateProfile/request',
  UPDATE_PROFILE_SUCCESS = '@auth/updateProfile/success',
  UPDATE_PROFILE_FAILURE = '@auth/updateProfile/failure',

  UPDATE_AVATAR_REQUEST = '@auth/updateAvatar/request',
  UPDATE_AVATAR_SUCCESS = '@auth/updateAvatar/success',
  UPDATE_AVATAR_FAILURE = '@auth/updateAvatar/failure',

  RECOVERY_BY_EMAIL_INIT = '@auth/recoveryByEmail/init',
  RECOVERY_BY_EMAIL_REQUEST = '@auth/recoveryByEmail/request',
  RECOVERY_BY_EMAIL_SUCCESS = '@auth/recoveryByEmail/success',
  RECOVERY_BY_EMAIL_FAILURE = '@auth/recoveryByEmail/failure',

  CHANGE_PASSWORD_INIT = '@auth/changePassword/init',
  CHANGE_PASSWORD_REQUEST = '@auth/changePassword/request',
  CHANGE_PASSWORD_SUCCESS = '@auth/changePassword/success',
  CHANGE_PASSWORD_FAILURE = '@auth/changePassword/failure',

  DELETE_REQUEST = '@auth/delete/request',
  DELETE_SUCCESS = '@auth/delete/success',
  DELETE_FAILURE = '@auth/delete/failure',

  ACTIVATE_ACCOUNT_REQUEST = '@auth/activate_account/request',
  ACTIVATE_ACCOUNT_SUCCESS = '@auth/activate_account/success',
  ACTIVATE_ACCOUNT_FAIL = '@auth/activate_account/failure',

  RE_SEND_ACTIVATION_LINK_REQUEST = '@auth/send_activation_link/request',
  RE_SEND_ACTIVATION_LINK_SUCCESS = '@auth/send_activation_link/success',
  RE_SEND_ACTIVATION_LINK_FAIL = '@auth/send_activation_link/failure',

  LOGOUT = '@auth/logout',

  CHECK_USER_SESSION = '@auth/check_user_session',

  GET_ROLE = '@auth/get_role',
  REFRESH_USER = '@auth/refresh_user',

  CHECK_TOKEN_REQUEST = '@auth/check_token/request',
  CHECK_TOKEN_SUCCESS = '@auth/check_token/success',
  CHECK_TOKEN_FAILURE = '@auth/check_token/failure',
}

/**
 * Data Types
 */
export interface Auth extends AuthType {}
export interface User extends UserType {}

/**
 * State type
 */
export interface AuthState {
  readonly auth: Auth
  readonly user: User
  readonly token: Token
  readonly message: string
  readonly validations: ValidationsError[]
  readonly role: string
  readonly status: number

  readonly loading: boolean
  readonly isLoggedIn: boolean
  readonly isAccountActivated: boolean
  readonly isRecoveryEmail: boolean
  readonly isError: boolean
}
