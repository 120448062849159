import { lazy } from 'react'

// project imports
import MainLayout from '../layouts/MainLayout'
import Loadable from '../ui-component/Loadable'
import ProtectedRoute from '../components/PrivateRoute'
import { useAuth } from '../hooks'

const HomePage = Loadable(lazy(() => import('../pages/site/Home')))
const SearchPage = Loadable(lazy(() => import('../pages/site/Search')))
const ComoFuncionaPage = Loadable(lazy(() => import('../pages/site/ComoFunciona')))
const ComoFazerAulaExperimentalPage = Loadable(
  lazy(() => import('../pages/site/ComoFazerAulaExperimental')),
)
const ComoSerProfessor = Loadable(lazy(() => import('../pages/site/ComoSerProfessor')))
const ValoresPage = Loadable(lazy(() => import('../pages/site/Valores')))
const QuemSomosPage = Loadable(lazy(() => import('../pages/site/QuemSomos')))
const FaqPage = Loadable(lazy(() => import('../pages/site/FAQ')))
const DepoimentosPage = Loadable(lazy(() => import('../pages/site/Depoimentos')))
const FaleConoscoPage = Loadable(lazy(() => import('../pages/site/FaleConosco')))
const PoliticaPrivacidadePage = Loadable(lazy(() => import('../pages/site/PoliticaPrivacidade')))
const CookiesPage = Loadable(lazy(() => import('../pages/site/Cookies')))
const TermoUsoPage = Loadable(lazy(() => import('../pages/site/TermoUso')))

const PerfilProfissionalPage = Loadable(lazy(() => import('../pages/site/PerfilProfissional')))
const AgendarAulaExperimentalPage = Loadable(
  lazy(() => import('../pages/site/AgendarAulaExperimental')),
)

const InvitePage = Loadable(lazy(() => import('../pages/site/Invite')))

const PurchaseFragment = Loadable(
  lazy(() => import('../pages/site/AgendarAulaExperimental/Purchase')),
)
const PaymentFragment = Loadable(
  lazy(() => import('../pages/site/AgendarAulaExperimental/Payment')),
)
const ConfirmPaymentFragment = Loadable(
  lazy(() => import('../pages/site/AgendarAulaExperimental/ConfirmPayment')),
)

const BlogPage = {
  Index: Loadable(lazy(() => import('../pages/site/Blog'))),
  Posts: Loadable(lazy(() => import('../pages/site/Blog/Post'))),
  Categories: Loadable(lazy(() => import('../pages/site/Blog/Categories'))),
  Search: Loadable(lazy(() => import('../pages/site/Blog/Search'))),
}

const RestrictedAreaPages = {
  Index: Loadable(lazy(() => import('../pages/site/RestrictedArea'))),
  Student: {
    Index: Loadable(lazy(() => import('../pages/site/RestrictedArea/Student'))),
    Fragment: {
      MyNotices: Loadable(lazy(() => import('../pages/site/RestrictedArea/Student/MyNotices'))),
      ReferFriends: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Student/ReferFriends')),
      ),
      PurchasesMade: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Student/PurchasesMade')),
      ),
      ClassesTeacher: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Student/ClassesTeacher')),
      ),
      MyCadastre: Loadable(lazy(() => import('../pages/site/RestrictedArea/Student/MyCadastre'))),
      ChangePassword: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Student/ChangePassword')),
      ),
    },
  },
  Teacher: {
    Index: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher'))),
    Fragment: {
      MyNotices: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher/MyNotices'))),
      MySchedule: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher/MySchedule'))),
      TrafficLights: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Teacher/TrafficLights')),
      ),
      MyPlan: {
        Index: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher/MyPlan'))),
        Fragment: {
          Plan: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher/MyPlan/Plan'))),
          Payment: Loadable(
            lazy(() => import('../pages/site/RestrictedArea/Teacher/MyPlan/Payment')),
          ),
        },
      },
      MyCadastre: Loadable(lazy(() => import('../pages/site/RestrictedArea/Teacher/MyCadastre'))),
      ChangePassword: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Teacher/ChangePassword')),
      ),
      ReferFriends: Loadable(
        lazy(() => import('../pages/site/RestrictedArea/Teacher/ReferFriends')),
      ),
    },
  },
}

const ErrorPages = {
  Error404: Loadable(lazy(() => import('../pages/site/404'))),
}

const MainRoutes = () => {
  const auth = useAuth()
  return {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <HomePage title='EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/home',
        element: <HomePage title='EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/como-funciona',
        element: <ComoFuncionaPage title='Como Funciona · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/como-fazer-aula-experimental',
        element: (
          <ComoFazerAulaExperimentalPage title='Como fazer sua aula experimental · EWA - Aulas Particulares de Inglês' />
        ),
      },
      {
        path: '/como-anunciar-suas-aulas',
        element: (
          <ComoSerProfessor title='Como anunciar suas aulas · EWA - Aulas Particulares de Inglês' />
        ),
      },
      {
        path: '/valores',
        element: <ValoresPage title='Valores · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/quem-somos',
        element: <QuemSomosPage title='Quem Somos · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/faq',
        element: (
          <FaqPage title='FAQ · Perguntas Frequentes · EWA - Aulas Particulares de Inglês' />
        ),
      },
      {
        path: '/depoimentos',
        element: <DepoimentosPage title='Depoimentos · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/fale-conosco',
        element: <FaleConoscoPage title='Fale Conosco · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/aulas-de-ingles',
        element: <SearchPage />,
      },
      {
        path: '/perfil-profissional/:profileId',
        element: <PerfilProfissionalPage />,
      },
      {
        path: '/agendar-aula-experimental',
        element: <AgendarAulaExperimentalPage />,
        children: [
          {
            index: true,
            path: 'purchase',
            element: <PurchaseFragment />,
          },
          {
            path: 'payment',
            element: <PaymentFragment />,
          },
          {
            path: 'confirm-payment',
            element: <ConfirmPaymentFragment />,
          },
        ],
      },
      {
        path: '/politica-privacidade',
        element: (
          <PoliticaPrivacidadePage title='Política de Privacidade · EWA - Aulas Particulares de Inglês' />
        ),
      },
      {
        path: '/termo-uso',
        element: <TermoUsoPage title='Termos de Uso · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/invite/:id',
        element: <InvitePage title='Termos de Uso · EWA - Aulas Particulares de Inglês' />,
      },
      {
        path: '/cookies',
        element: <CookiesPage />,
      },
      {
        path: '/blog',
        children: [
          {
            index: true,
            element: <BlogPage.Index />,
          },
          {
            path: 'category/:category',
            element: <BlogPage.Categories />,
          },
          {
            path: 'posts/:id',
            element: <BlogPage.Posts />,
          },
          {
            path: 'search',
            element: <BlogPage.Search />,
          },
        ],
      },
      {
        path: '/restricted-area',
        element: <RestrictedAreaPages.Index />,
        children: [
          {
            path: 'student',
            element: (
              <ProtectedRoute isRolePermission rolePermission='student'>
                <RestrictedAreaPages.Student.Index />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: !auth?.user?.accountComplete ? (
                  <RestrictedAreaPages.Student.Fragment.MyCadastre />
                ) : (
                  <RestrictedAreaPages.Student.Fragment.MyNotices />
                ),
              },
              {
                path: 'my-notices',
                element: <RestrictedAreaPages.Student.Fragment.MyNotices />,
              },
              {
                path: 'refer-friends',
                element: <RestrictedAreaPages.Student.Fragment.ReferFriends />,
              },
              {
                path: 'purchases-made',
                element: <RestrictedAreaPages.Student.Fragment.PurchasesMade />,
              },
              {
                path: 'classes-teacher',
                element: <RestrictedAreaPages.Student.Fragment.ClassesTeacher />,
              },
              {
                path: 'my-cadastre',
                element: <RestrictedAreaPages.Student.Fragment.MyCadastre />,
              },
              {
                path: 'change-password',
                element: <RestrictedAreaPages.Student.Fragment.ChangePassword />,
              },
            ],
          },
          {
            path: 'teacher',
            element: (
              <ProtectedRoute isRolePermission rolePermission='teacher'>
                <RestrictedAreaPages.Teacher.Index />
              </ProtectedRoute>
            ),
            children: [
              {
                index: true,
                element: <RestrictedAreaPages.Teacher.Fragment.MyNotices />,
              },
              {
                path: 'my-notices',
                element: <RestrictedAreaPages.Teacher.Fragment.MyNotices />,
              },
              {
                path: 'my-schedule',
                element: <RestrictedAreaPages.Teacher.Fragment.MySchedule />,
              },
              {
                path: 'traffic-lights',
                element: <RestrictedAreaPages.Teacher.Fragment.TrafficLights />,
              },
              {
                path: 'my-plan',
                element: <RestrictedAreaPages.Teacher.Fragment.MyPlan.Index />,
                children: [
                  {
                    index: true,
                    element: <RestrictedAreaPages.Teacher.Fragment.MyPlan.Fragment.Plan />,
                  },
                  {
                    path: 'payment/:planId',
                    element: <RestrictedAreaPages.Teacher.Fragment.MyPlan.Fragment.Payment />,
                  },
                ],
              },
              {
                path: 'my-cadastre',
                element: <RestrictedAreaPages.Teacher.Fragment.MyCadastre />,
              },
              {
                path: 'change-password',
                element: <RestrictedAreaPages.Teacher.Fragment.ChangePassword />,
              },
              {
                path: 'refer-friends',
                element: <RestrictedAreaPages.Teacher.Fragment.ReferFriends />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <ErrorPages.Error404 />,
      },
    ],
  }
}

export default MainRoutes
